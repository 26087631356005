import { api } from './api';

export async function getTotalFolders() {
  const { data } = await api.post('database/folder/aggregate', [
    { $match: { type: 'course' } },
    { $group: { _id: null, total: { $sum: 1 } } },
  ]);

  return data;
}

export async function getTotalFinishCourse(userId: string) {
  const { data } = await api.post('database/action_log/aggregate', [
    { $match: { actionId: 'finish_course', userId: userId } },
    { $sort: { time: -1 } },
    {
      $project: {
        _id: 1,
        time: 1,
      },
    },
    {
      $group: {
        _id: null,
        total: { $sum: 1 },
        lastId: { $first: '$_id' },
        lastTime: { $first: '$time' },
      },
    },
  ]);

  return data;
}

export async function getActionLogFinishCourse(userId: string, courseId: string) {
  const { data } = await api.post('database/action_log/aggregate', [
    { $match: { actionId: 'finish_course', userId: userId, 'attributes.course': courseId } },
    { $sort: { time: -1 } },
    {
      $project: {
        _id: 1,
        time: 1,
      },
    },
    {
      $group: {
        _id: null,
        total: { $sum: 1 },
        lastId: { $first: '$_id' },
        lastTime: { $first: '$time' },
      },
    },
  ]);

  return data;
}

export async function getFinishCourse(userId: string, courseId: string) {
  const { data } = await api.post('database/action_log/aggregate', [
    { $match: { actionId: 'finish_course', userId: userId, 'attributes.course': courseId } },
  ]);

  return data;
}

export async function getCertificado(id: string): Promise<any[]> {
  const { data } = await api.get(`database/certification_style__c?q=courseId:"${id}"`);

  if (!Array.isArray(data)) {
    return [];
  } else {
    return data;
  }
}
