import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { BiGame, BiLogOut, BiRefresh, BiUser } from 'react-icons/bi';
import { ImExit } from 'react-icons/im';

import AvatarEditor from 'components/AvatarEditor';
import { useAuth, useBasic, useModal } from 'contexts';
import { AiOutlineTrophy } from 'react-icons/ai';
import { BsCoin } from 'react-icons/bs';
import { FaCog, FaMedal } from 'react-icons/fa';
import { IoSettings } from 'react-icons/io5';
import { MdSettings } from 'react-icons/md';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { fetchDataURL, resizeImage, updateUserProfile, uploadPicture } from 'services/profile.service';
import { getTotalFolders, getTotalFinishCourse, getCertificado } from 'services';
import { GrCertificate } from 'react-icons/gr';
// import { CompleteRegister } from 'components/CompleteRegister';
import { ModalPrintCertificate } from 'components/ModalPrintCertificate';

export default function UserInfo() {
  const navigate = useNavigate();
  const location = useLocation();

  const { userPoints } = useBasic();
  const { user, refreshUser, signOut } = useAuth();
  const { appearance } = useBasic();
  const [avatarLoading, setAvatarLoading] = useState(false);
  const avatarEditor = useRef<any>();
  const [registerTypes, setRegisterTypes] = useState<any>({});

  const [printCertifield, setHasPrintCertifield] = useState(false);
  const { showModal, closeModal } = useModal();

  useEffect(() => {
    if (user && !user.extra.acceptTerms) {
      navigate('/configuracoes/termos-de-uso');
    }
  }, [user, location, navigate]);

  const onOpenAvatarEditor = () => {
    if (avatarLoading) return;
    avatarEditor.current?.open();
  };

  async function updateAvatarImage(dataURI: string) {
    try {
      const { uploads } = await uploadPicture(dataURI);
      const [{ url }] = uploads;
      if (url) {
        const url = uploads[0].url;
        const payload = {
          _id: user?._id,
          image: {
            small: { url },
            medium: { url },
            original: { url },
          },
        };

        const updatedUser = await updateUserProfile(payload);
        refreshUser();

        // console.log(updatedUser);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function prepareToUpdateAvatar(url: string) {
    if (!user) return;

    setAvatarLoading(true);

    const imgResult = await fetchDataURL(url);
    if (imgResult) {
      const imgResized = await resizeImage(imgResult);
      await updateAvatarImage(imgResized);
    }
  }

  const renderAlternativeNav = useCallback(() => {
    if (!appearance || appearance.navigation?.displayMenu) {
      return;
    }

    return (
      <div className="flex-1 flex pl-3 gap-3">
        <Link to="/games/runner" className="ring-2 ring-blue-300 text-blue-600 inline-flex rounded-xl p-3 items-center gap-3">
          <BiGame size={30} /> <b>GAME</b>
        </Link>
        <Link to="/ranking" className="ring-2 ring-yellow-300 text-yellow-600 inline-flex rounded-xl p-3 items-center gap-3">
          <AiOutlineTrophy size={30} /> <b>RANKING</b>
        </Link>
      </div>
    );
  }, [appearance]);

  useEffect(() => {
    if (user) {
      hasPrintCertificate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  async function hasPrintCertificate() {
    if (!user) return;
    const data = await getTotalFolders();
    const data1 = await getTotalFinishCourse(user._id);

    if (data.length > 0 && data1.length > 0) {
      if (data[0].total === data1[0].total) {
        setHasPrintCertifield(true);
      }
    }
  }

  async function printCertified() {
    // let total = 0;
    // const register = await getRegisterTypes();
    const certificado = await getCertificado('geral');

    // const filteredRegister = register.attributes.filter((e: any) => !e.required);
    // setRegisterTypes(filteredRegister);

    // filteredRegister.forEach((e: any) => {
    //   const campoName = e.name;
    //   if (!user) return;
    //   if (!(campoName in user.extra)) {
    //     total++;
    //   }
    // });

    // if (total > 0) {
    //   showModal('text', <CompleteRegister content={filteredRegister} />, 'text-black');
    // } else {
    //   showModal('text', <ModalPrintCertificate certifield={certificado} />, 'text-black');
    // }

    showModal('text', <ModalPrintCertificate certifield={certificado} isGeral={true} />, 'text-black');
  }

  return (
    <>
      <div id="user-info" className="hidden lg:block relative">
        <div className="lg:flex items-center justify-between">
          <div className="lg:flex items-center gap-3">
            <div className="hidden lg:block avatar w-16 h-16 bg-gray-100 rounded-full">
              <button onClick={onOpenAvatarEditor} className="w-full h-full flex items-center justify-center">
                {!avatarLoading && !!user?.image?.medium?.url && (
                  <img id="avatar" src={user?.image.medium.url} alt="" className="w-full h-full rounded-full overflow-hidden" />
                )}
                {!avatarLoading && !user?.image?.medium?.url && <BiUser size={32} />}
                {avatarLoading && (
                  <span>
                    <BiRefresh size={32} className="animate-spin" />
                  </span>
                )}
              </button>
            </div>
            <div className="user-gamification">
              <h2 className="username text-center mt-5 mb-5 block lg:hidden text-xl font-bold leading-none">{user?.name}</h2>

              <div className="hidden md:flex divide-x divide-white items-center justify-center lg:justify-start text-center px-3 py-2 rounded-xl border border-white border-solid lg:border-0 border-opacity-20 lg:border-opacity-0 lg:bg-gray-100">
                <h2 className="hidden lg:block text-xl font-bold leading-none pr-2">{user?.name}</h2>

                {userPoints?.map(point => (
                  <div
                    key={`${point._id}_${point.total}`}
                    className="item flex flex-col items-center justify-start leading-none px-2"
                  >
                    <div>
                      <strong className="whitespace-nowrap">{point.category}</strong>
                    </div>
                    <div>{point.total}</div>
                  </div>
                ))}

                {!!user?.level?.level && (
                  <div className="item flex flex-col items-center justify-start leading-none px-3">
                    <div>
                      <strong className="whitespace-nowrap">{user.level.level}</strong>
                    </div>
                    <div>{Math.round(user.level_progress.percent_completed)}%</div>
                  </div>
                )}

                {printCertifield && (
                  <div className="item flex flex-col items-center justify-start leading-none px-3">
                    <div>
                      <strong className="whitespace-nowrap">Certificado</strong>
                    </div>
                  </div>
                )}
              </div>

              {printCertifield && (
                <div className="hidden md:flex  mt-1 mb-3">
                  <button
                    className="flex items-center bg-[#503e9d40] text-[#503e9d] rounded-full py-1 px-3"
                    onClick={printCertified}
                  >
                    {' '}
                    <GrCertificate className="mr-1" /> Certificado geral
                  </button>
                </div>
              )}

              <div className="flex md:hidden divide-x divide-white items-center justify-center lg:justify-start text-center py-2 rounded-xl border border-white border-solid lg:border-0 border-opacity-20 lg:border-opacity-0 lg:bg-gray-100">
                {userPoints?.map(point => (
                  <div
                    key={`${point._id}_${point.total}`}
                    className="item flex flex-col items-center justify-start leading-none px-3"
                  >
                    <div>
                      <strong className="whitespace-nowrap">{point.category}</strong>
                    </div>
                    <div>{point.total}</div>
                  </div>
                ))}

                {!!user?.level?.level && (
                  <div className="item flex flex-col items-center justify-start leading-none px-3">
                    <div>
                      <strong className="whitespace-nowrap">{user.level.level}</strong>
                    </div>
                    <div>{Math.round(user.level_progress.percent_completed)}%</div>
                  </div>
                )}

                {printCertifield && (
                  <div className="item flex flex-col items-center justify-start leading-none px-3">
                    <div>
                      <strong className="whitespace-nowrap">Certificado</strong>
                    </div>
                  </div>
                )}
              </div>

              {printCertifield && (
                <div className="flex md:hidden mt-1 mb-3">
                  <button className="flex items-center bg-[#FFF] text-[#503e9d] rounded-full py-1 px-3" onClick={printCertified}>
                    {' '}
                    <GrCertificate className="mr-1" /> Certificado geral
                  </button>
                </div>
              )}
            </div>
          </div>
          {renderAlternativeNav()}

          <div className="logout text-right lg:flex space-x-3">
            <button type="button" className="text-indigo-400 hidden lg:flex" onClick={() => navigate('/configuracoes')}>
              <FaCog size={35} />
            </button>
            <button onClick={() => signOut()} type="button" className="text-red-600 opacity-50" title="Sair">
              {/* <BiLogOut size={44} /> */}
              <ImExit size={35} />
            </button>
          </div>
        </div>
      </div>
      <AvatarEditor
        ref={avatarEditor}
        onRenderStart={() => {
          setAvatarLoading(true);
        }}
        onRenderFinished={url => {
          prepareToUpdateAvatar(url);
        }}
      />
    </>
  );
}
