import { useEffect, useState } from 'react';
import { FaMedal } from 'react-icons/fa';

import { Placeholder } from 'components';
import { useAuth, useBasic, useModal } from 'contexts';

import './style.scss';
import { BsCoin } from 'react-icons/bs';
import { getTotalFolders, getTotalFinishCourse, getCertificado } from 'services';
// import { CompleteRegister } from 'components/CompleteRegister';
import { ModalPrintCertificate } from 'components/ModalPrintCertificate';
import { GrCertificate } from 'react-icons/gr';

type UserStatsProps = {
  className?: string;
  [key: string]: any;
};

export function UserStats({ className, ...rest }: UserStatsProps) {
  const { user } = useAuth();
  const { userPoints, pointsCategories } = useBasic();
  const { showModal } = useModal();

  const [printCertifield, setHasPrintCertifield] = useState(false);

  async function printCertified() {
    // let total = 0;
    // const register = await getRegisterTypes();

    // const filteredRegister = register.attributes.filter((e: any) => !e.required);
    const certificado = await getCertificado('geral');

    // filteredRegister.forEach((e: any) => {
    //   const campoName = e.name;
    //   if (!user) return;
    //   if (!(campoName in user.extra)) {
    //     total++;
    //   }
    // });

    // if (total > 0) {
    //   showModal('text', <CompleteRegister content={filteredRegister} />, 'text-black');
    // } else {
    //   showModal('text', <ModalPrintCertificate certifield={certificado} />, 'text-black');
    // }

    showModal('text', <ModalPrintCertificate certifield={certificado} isGeral={true} />, 'text-black');
  }

  function loader() {
    return (
      <div className="w-full grid grid-cols-2 h-48 gap-4">
        <div className="grid h-48 gap-4">
          <Placeholder className="w-full" />
          <Placeholder className="w-full" />
        </div>
        <div className="grid h-48 gap-4">
          <Placeholder className="w-full" />
          <Placeholder className="w-full" />
        </div>
      </div>
    );
  }

  useEffect(() => {
    if (user) {
      hasPrintCertificate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  async function hasPrintCertificate() {
    if (!user) return;
    const data = await getTotalFolders();
    const data1 = await getTotalFinishCourse(user._id);

    if (data.length > 0 && data1.length > 0) {
      if (data[0].total === data1[0].total) {
        setHasPrintCertifield(true);
      }
    }
  }

  function render() {
    return (
      <>
        {!!userPoints && (
          <div className="points w-full block">
            <h3 className="title text-lg mb-2 w-full block font-medium">Pontos</h3>
            {userPoints.map(point => (
              <div key={`${point._id}_${point.total}`} className="item flex items-center justify-start">
                <span className="img flex items-center justify-center">
                  {!!point.image ? (
                    <>
                      <img src={point.image} alt={point.category} className="block w-full h-full" />
                    </>
                  ) : (
                    <>
                      {!!point._id?.match(/xp/gi) ? (
                        <FaMedal className="block w-full h-full" />
                      ) : (
                        <BsCoin className="block w-full h-full" />
                      )}
                    </>
                  )}
                </span>

                <p className="">
                  <strong className="">{point.category}</strong>
                  {point.total}
                </p>
              </div>
            ))}
          </div>
        )}

        {user?.level?.level ? (
          <div className="levels w-full block border-t lg:border-t-0 lg:border-l border-gray-100 border-solid">
            <h3 className="title text-lg mb-2 w-full block font-medium">Níveis</h3>

            <div className="item">
              <p className="actual">
                <strong>Nível atual</strong>
                {user.level.level}
              </p>
            </div>

            {user?.level_progress?.next_level?.level ? (
              <div className="item">
                <p>
                  <strong>Próximo nível</strong>
                  <span className="progress flex items-center justify-between w-full">
                    <span className="actual">{user.level.level}</span>
                    <span className="bar w-full block h-5 bg-gray-200 rounded">
                      <span
                        className="block h-5 rounded animate-bar-stripes bg-bar-stripes bg-size-bar-stripes"
                        style={{ width: `${user.level_progress.percent}%` }}
                      >
                        {Math.round(user.level_progress.percent)}%
                      </span>
                    </span>
                    <span className="next">{user.level_progress.next_level.level}</span>
                  </span>
                </p>
              </div>
            ) : null}
          </div>
        ) : null}

        {printCertifield && (
          <div className="levels w-full block border-t lg:border-t-0 lg:border-l border-gray-100 border-solid">
            <div>
              <h3 className="title text-lg mb-2 w-full block font-medium">Certificado</h3>
            </div>

            <div className="mt-3">
              <button onClick={printCertified} className="flex bg-[#503e9d40] text-[#503e9d] rounded-full py-1 px-3 items-center">
                {' '}
                <GrCertificate className="mr-1" /> Certificado geral
              </button>
            </div>
          </div>
        )}
      </>
    );
  }

  if (!user || !pointsCategories) {
    return <div className={`user-stats ${className || ''}`}>{loader()}</div>;
  }

  if (!userPoints && !user?.level?.level) {
    return <></>;
  }

  return <div className={`user-stats ${className || ''}`}>{render()}</div>;
}
