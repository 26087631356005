import { useEffect, useState } from 'react';
import { useAuth, useBasic } from 'contexts';
import { actionLog, getTotalFinishCourse, getActionLogFinishCourse } from 'services';
import moment from 'moment';
import 'moment/locale/pt-br';

import './style.scss';

moment.locale('pt-br');

type Props = {
  certifield: any[];
  className?: string;
  isGeral?: boolean;
  courseId?: string;
  [key: string]: any;
};

export function ModalPrintCertificate({ certifield, className, isGeral, courseId, ...rest }: Props) {
  const { user } = useAuth();
  const { appearance } = useBasic();
  const [log, setLog] = useState<any>();

  const loadLogGeral = async () => {
    try {
      if (!user) return;
      const data = await getTotalFinishCourse(user._id);
      console.log('Data from getQuizLog:', data);
      if (data && data.length > 0) {
        setLog(data[0]);
      } else {
        console.error('No data returned from getTotalFinishCourse');
      }
    } catch (error) {
      console.error('Error fetching data from getTotalFinishCourse:', error);
    }
  };

  const loadLogEspecifico = async () => {
    try {
      if (!user || !courseId) return;
      const data = await getActionLogFinishCourse(user._id, courseId);
      if (data && data.length > 0) {
        setLog(data[0]);
      } else {
        console.error('No data returned from getActionLogFinishCourse');
      }
    } catch (error) {
      console.error('Error fetching data from getActionLogFinishCourse:', error);
    }
  };

  useEffect(() => {
    if (isGeral) {
      loadLogGeral();
    } else {
      loadLogEspecifico();
    }

    const modalContents = document.getElementsByClassName('modal-content');
    if (modalContents.length > 0) {
      for (let i = 0; i < modalContents.length; i++) {
        modalContents[i].classList.add('modal-content-print');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handlePrint = () => {
    window.print();
    actionLog('imprimir_certificado');
  };

  if (!certifield) return null;

  return (
    <section id="print" className={className}>
      <button onClick={handlePrint} id="btnPrint">
        Imprimir Certificado
      </button>
      <div id="printContent">
        <img src={appearance?.images.logo} className="logo" alt={appearance?.title} title={appearance?.title} />
        {certifield[0] && certifield[0].image && <img src={certifield[0].image.original.url} alt="" className="imgCertificado" />}
        <p className="nome">{user?.name}</p>
        <p className="cpf">{user?.extra.cpf}</p>
        {certifield[0] && certifield[0].title && <p className="certificacao">{certifield[0].title}</p>}

        <p className="cargaHoraria">{certifield[0].cargaHoraria ? `${certifield[0].cargaHoraria}` : ''}</p>

        {certifield[0] && certifield[0].baseLegal && <p className="baseLegal">{certifield[0].baseLegal}</p>}
        <p className="data">{log && moment(log.lastTime).format('DD/MM/yyyy')}</p>
        <p className="codigo">{log && log.lastId}</p>
      </div>
    </section>
  );
}
