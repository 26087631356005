import { useState, useMemo, useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { useAuth, useCourses, useModal, useToast } from 'contexts';
import { Placeholder } from 'components';
import { getCourseStatus } from 'utils';
import Tree from './Tree';
import { ProgressInterface } from 'types';
import { getFinishCourse, getCertificado } from 'services';
import { ModalPrintCertificate } from 'components/ModalPrintCertificate';
import { GrCertificate } from 'react-icons/gr';

import './style.scss';

import noImage from 'assets/images/no-image.jpg';

export default function CoursesDetailPage() {
  const { user } = useAuth();
  const { allProgress, listCourses } = useCourses();
  const { courseID } = useParams();

  const [opened, setOpened] = useState<any>();
  const { showModal, closeModal } = useModal();
  const [printCertifield, setHasPrintCertifield] = useState(false);
  const { toastify } = useToast();

  const course = useMemo(() => {
    if (Array.isArray(listCourses) && listCourses.length) {
      const _course = listCourses.find(x => x._id === courseID);

      if (!!_course) {
        return _course;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }, [courseID, listCourses]);

  const courseProgress = useMemo(() => {
    if (Array.isArray(allProgress) && allProgress.length) {
      const _course = allProgress.find(x => x._id === courseID);

      if (!!_course) {
        return _course;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }, [allProgress, courseID]);

  useEffect(() => {
    if (user && courseID) {
      hasPrintCertificate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, courseID]);

  async function hasPrintCertificate() {
    if (!user || !courseID) return;
    const data = await getFinishCourse(user._id, courseID);
    const certificado = await getCertificado(courseID);

    if (data.length > 0 && certificado.length > 0) {
      setHasPrintCertifield(true);
    }
  }

  async function printCertified() {
    if (!courseID) return;
    const certificado = await getCertificado(courseID);

    if (certificado.length === 0) {
      toastify({
        content: 'Não foi encontrado o certificado deste curso.',
        type: 'error',
      });
    } else {
      showModal('text', <ModalPrintCertificate certifield={certificado} courseId={courseID} />, 'text-black');
    }
  }

  if (!Array.isArray(listCourses)) {
    return (
      <>
        <div className="flex flex-col space-y-6">
          <div className="flex flex-row space-x-3">
            <Placeholder className="w-10 h-10 rounded-full" />
            <Placeholder className="flex-1 h-10 rounded-full" />
          </div>

          <Placeholder className="h-64" />

          <div className="grid grid-cols-3 h-48 gap-6">
            <Placeholder />
            <Placeholder />
            <Placeholder />
          </div>
        </div>
      </>
    );
  }

  if (!course) {
    return <Navigate to={`/erro-404`} replace />;
  }

  return (
    <>
      <section id="courses-detail">
        <div className="infos">
          <div className="img overflow-hidden mr-5 flex items-center justify-center">
            <img
              src={course.extra?.image?.length ? course.extra?.image : noImage}
              alt={course.title || ' '}
              className="w-full h-full block object-cover transition-transform duration-300 ease-in-out"
            />
          </div>
          <div className="data">
            {!!course.title && <h2>{course.title}</h2>}

            {!!getCourseStatus(courseProgress?.percent).length && (
              <p className="status text-sm lg:text-base not-italic font-bold leading-tight uppercase">
                {getCourseStatus(courseProgress?.percent)}
              </p>
            )}
            {typeof courseProgress?.percent === 'number' && courseProgress?.percent > 0 && (
              <p className="progress text-sm lg:text-base text-black not-italic font-bold leading-tight">
                {`${Math.round(courseProgress?.percent)}% concluído`}
              </p>
            )}

            {!!course.extra?.description && (
              <div className="description text-sm" dangerouslySetInnerHTML={{ __html: course.extra.description }} />
            )}

            {printCertifield && (
              <div className="hidden md:flex  mt-1 mb-3">
                <button
                  className="flex items-center bg-[#503e9d40] text-[#503e9d] rounded-full py-2 px-5 mt-5"
                  onClick={printCertified}
                >
                  {' '}
                  <GrCertificate className="mr-1" /> Certificado do curso
                </button>
              </div>
            )}
          </div>
        </div>

        {!!courseProgress && !!courseID && (
          <div className="content">
            <h3>Conteúdo programático</h3>
            <Tree items={courseProgress?.items as ProgressInterface[]} parent={courseProgress._id} courseID={courseID} />
          </div>
        )}
      </section>
    </>
  );
}
